import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { Stars } from '@react-three/drei';
import * as THREE from 'three';
import Spaceship from './Spaceship';
import Controls from './Controls';

// Camera controller component
function CameraController({ playerId, players, cameraMode }) {
  const { camera } = useThree();
  const targetPosition = useRef(new THREE.Vector3(0, 0, 0));
  const targetLookAt = useRef(new THREE.Vector3(0, 0, 0));
  const smoothFactor = 0.1; // Lower for smoother camera movement
  
  // Reusable vectors to avoid garbage collection
  const tempLookAt = useMemo(() => new THREE.Vector3(), []);
  const shipQuaternion = useMemo(() => new THREE.Quaternion(), []);
  const offset = useMemo(() => new THREE.Vector3(), []);
  const lookDirection = useMemo(() => new THREE.Vector3(), []);
  
  useEffect(() => {
    // Set initial camera position
    camera.position.set(0, 10, 20);
    camera.lookAt(0, 0, 0);
  }, [camera]);
  
  useFrame(() => {
    // Follow the player's ship if it exists
    if (playerId && players[playerId]) {
      const playerPos = players[playerId].position;
      const playerRot = players[playerId].rotation;
      
      // Create direction vectors based on ship rotation
      shipQuaternion.setFromEuler(
        new THREE.Euler(playerRot.x, playerRot.y, playerRot.z, 'XYZ')
      );
      
      // Different camera modes
      if (cameraMode === 'follow') {
        // Follow behind the ship
        offset.set(0, 5, 15); // Behind and above
        offset.applyQuaternion(shipQuaternion);
        
        targetPosition.current.set(
          playerPos.x - offset.x,
          playerPos.y - offset.y,
          playerPos.z - offset.z
        );
        
        targetLookAt.current.set(playerPos.x, playerPos.y, playerPos.z);
      } 
      else if (cameraMode === 'cockpit') {
        // First-person view from cockpit
        offset.set(0, 0.7, -0.5); // Slightly above and forward of center
        offset.applyQuaternion(shipQuaternion);
        
        targetPosition.current.set(
          playerPos.x + offset.x,
          playerPos.y + offset.y,
          playerPos.z + offset.z
        );
        
        // Look in the direction the ship is facing
        lookDirection.set(0, 0, -10);
        lookDirection.applyQuaternion(shipQuaternion);
        
        targetLookAt.current.set(
          playerPos.x + lookDirection.x,
          playerPos.y + lookDirection.y,
          playerPos.z + lookDirection.z
        );
      }
      else { // 'orbit' mode
        // Orbit view - fixed position relative to ship
        targetPosition.current.set(
          playerPos.x,
          playerPos.y + 10,
          playerPos.z + 20
        );
        
        targetLookAt.current.set(playerPos.x, playerPos.y, playerPos.z);
      }
      
      // Smooth camera movement
      camera.position.lerp(targetPosition.current, smoothFactor);
      
      // Create a temporary vector for the lookAt target
      tempLookAt.copy(targetLookAt.current);
      
      camera.lookAt(tempLookAt);
    }
  });
  
  return null;
}

// Player ship renderer - separated for performance
const PlayerShips = React.memo(({ players, playerId }) => {
  return (
    <>
      {Object.values(players).map((player) => (
        <Spaceship 
          key={player.id}
          position={player.position}
          rotation={player.rotation}
          color={player.color}
          isCurrentPlayer={player.id === playerId}
        />
      ))}
    </>
  );
});

// Main game scene component
function GameScene({ playerId, players, updatePosition, updateRotation }) {
  const controlsRef = useRef();
  const [cameraMode, setCameraMode] = useState('follow'); // 'follow', 'cockpit', or 'orbit'
  
  // Use local players state only for player-controlled ship to reduce rerenders
  const [localPlayerPosition, setLocalPlayerPosition] = useState({ x: 0, y: 0, z: 0 });
  const [localPlayerRotation, setLocalPlayerRotation] = useState({ x: 0, y: 0, z: 0 });
  
  // Initialize local position when playerId or players changes
  useEffect(() => {
    if (playerId && players[playerId]) {
      setLocalPlayerPosition({...players[playerId].position});
      setLocalPlayerRotation({...players[playerId].rotation});
    }
  }, [playerId, players]);
  
  // Handle camera mode switching
  const handleKeyDown = useCallback((e) => {
    // Camera mode switching
    if (e.code === 'Digit1') setCameraMode('follow');
    if (e.code === 'Digit2') setCameraMode('cockpit');
    if (e.code === 'Digit3') setCameraMode('orbit');
  }, []);
  
  // Add event listener for camera mode switching
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  // Create a merged players object that uses local state for current player
  const mergedPlayers = useMemo(() => {
    if (!playerId) return players;
    
    const result = {...players};
    
    // Use local state for the current player
    if (result[playerId]) {
      result[playerId] = {
        ...result[playerId],
        position: localPlayerPosition,
        rotation: localPlayerRotation
      };
    }
    
    return result;
  }, [players, playerId, localPlayerPosition, localPlayerRotation]);
  
  // Function to update local player position and rotation
  const handlePositionUpdate = useCallback((position) => {
    setLocalPlayerPosition(position);
    updatePosition(position);
  }, [updatePosition]);
  
  const handleRotationUpdate = useCallback((rotation) => {
    setLocalPlayerRotation(rotation);
    updateRotation(rotation);
  }, [updateRotation]);
  
  return (
    <div className="game-scene">
      <div className="camera-mode-indicator">
        Camera: {cameraMode.charAt(0).toUpperCase() + cameraMode.slice(1)} (Press 1-3 to change)
      </div>
      
      <Canvas shadows performance={{ min: 0.1 }}>
        {/* Environment */}
        <ambientLight intensity={0.1} />
        <directionalLight 
          position={[10, 10, 5]} 
          intensity={1} 
          castShadow 
          shadow-mapSize-width={1024} 
          shadow-mapSize-height={1024} 
        />
        <Stars radius={100} depth={50} count={3000} factor={4} saturation={0} fade />
        
        {/* Camera controls */}
        <CameraController 
          playerId={playerId} 
          players={mergedPlayers} 
          cameraMode={cameraMode} 
        />
        
        {/* Game grid for reference */}
        <gridHelper args={[1000, 100, '#444444', '#222222']} />
        
        {/* Player spaceships - using memo to prevent unnecessary rerenders */}
        <PlayerShips players={mergedPlayers} playerId={playerId} />
        
        {/* Player controls */}
        <Controls 
          ref={controlsRef}
          playerId={playerId}
          players={mergedPlayers}
          updatePosition={handlePositionUpdate}
          updateRotation={handleRotationUpdate}
        />
      </Canvas>
    </div>
  );
}

export default GameScene; 