import { forwardRef, useImperativeHandle, useRef, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';

// Controls component for handling player input with quaternion-based rotation
const Controls = forwardRef(({ playerId, players, updatePosition, updateRotation }, ref) => {
  // Track key states
  const keys = useRef({
    forward: false,    // W
    backward: false,   // S
    strafeLeft: false, // A
    strafeRight: false,// D
    up: false,         // Space
    down: false,       // Shift
    lookLeft: false,   // Left Arrow
    lookRight: false,  // Right Arrow
    lookUp: false,     // Up Arrow
    lookDown: false,   // Down Arrow
    rollLeft: false,   // Q
    rollRight: false   // E
  });
  
  // Local position and rotation state
  const localPosition = useRef({ x: 0, y: 0, z: 0 });
  const quaternion = useRef(new THREE.Quaternion()); // Using quaternion instead of Euler
  
  // Movement parameters
  const speed = 0.05;
  const rotationSpeed = 0.03;
  
  // Add throttling for updates
  const lastPositionUpdate = useRef(0);
  const lastRotationUpdate = useRef(0);
  const UPDATE_INTERVAL = 10; // ms between updates
  
  // Reuse vector objects to avoid garbage collection
  const xAxis = useRef(new THREE.Vector3(1, 0, 0));
  const yAxis = useRef(new THREE.Vector3(0, 1, 0));
  const zAxis = useRef(new THREE.Vector3(0, 0, 1));
  const forwardVector = useRef(new THREE.Vector3(0, 0, -1));
  const rightVector = useRef(new THREE.Vector3(1, 0, 0));
  const upVector = useRef(new THREE.Vector3(0, 1, 0));
  
  // Initialize local position when player data is available
  useEffect(() => {
    if (playerId && players[playerId]) {
      localPosition.current = { ...players[playerId].position };
      
      if (players[playerId].rotation) {
        // Convert Euler angles to quaternion for initialization
        const euler = new THREE.Euler(
          players[playerId].rotation.x || 0,
          players[playerId].rotation.y || 0,
          players[playerId].rotation.z || 0,
          'XYZ'
        );
        quaternion.current.setFromEuler(euler);
      }
    }
  }, [playerId, players]);
  
  // Expose methods to parent component
  useImperativeHandle(ref, () => ({
    handleKeyDown: (e) => {
      switch (e.code) {
        case 'KeyW': keys.current.forward = true; break;
        case 'KeyS': keys.current.backward = true; break;
        case 'KeyA': keys.current.strafeLeft = true; break;
        case 'KeyD': keys.current.strafeRight = true; break;
        case 'Space': 
          keys.current.up = true; 
          e.preventDefault(); 
          break;
        case 'ShiftLeft':
        case 'ShiftRight': 
          keys.current.down = true; 
          break;
        case 'ArrowLeft': 
          keys.current.lookLeft = true; 
          e.preventDefault(); 
          break;
        case 'ArrowRight': 
          keys.current.lookRight = true; 
          e.preventDefault(); 
          break;
        case 'ArrowUp': 
          keys.current.lookUp = true; 
          e.preventDefault(); 
          break;
        case 'ArrowDown': 
          keys.current.lookDown = true; 
          e.preventDefault(); 
          break;
        case 'KeyQ': keys.current.rollLeft = true; break;
        case 'KeyE': keys.current.rollRight = true; break;
        default: break;
      }
    },
    
    handleKeyUp: (e) => {
      switch (e.code) {
        case 'KeyW': keys.current.forward = false; break;
        case 'KeyS': keys.current.backward = false; break;
        case 'KeyA': keys.current.strafeLeft = false; break;
        case 'KeyD': keys.current.strafeRight = false; break;
        case 'Space': keys.current.up = false; break;
        case 'ShiftLeft':
        case 'ShiftRight': keys.current.down = false; break;
        case 'ArrowLeft': keys.current.lookLeft = false; break;
        case 'ArrowRight': keys.current.lookRight = false; break;
        case 'ArrowUp': keys.current.lookUp = false; break;
        case 'ArrowDown': keys.current.lookDown = false; break;
        case 'KeyQ': keys.current.rollLeft = false; break;
        case 'KeyE': keys.current.rollRight = false; break;
        default: break;
      }
    }
  }), []);
  
  // Update player position and rotation based on input
  useFrame(() => {
    if (!playerId || !players[playerId]) {
      return;
    }
    
    let position = { ...localPosition.current };
    let positionChanged = false;
    let rotationChanged = false;
    const now = Date.now();
    
    // Apply current orientation to local axes
    const currentRotation = quaternion.current;
    
    // Handle rotation with quaternions - proper spaceship control
    if (keys.current.lookLeft) {
      // Rotate around the up axis (yaw)
      const yawQ = new THREE.Quaternion().setFromAxisAngle(yAxis.current, rotationSpeed);
      quaternion.current.premultiply(yawQ);
      rotationChanged = true;
    }
    
    if (keys.current.lookRight) {
      // Rotate around the up axis (yaw) - opposite direction
      const yawQ = new THREE.Quaternion().setFromAxisAngle(yAxis.current, -rotationSpeed);
      quaternion.current.premultiply(yawQ);
      rotationChanged = true;
    }
    
    // Get the local right axis for proper pitch rotation
    xAxis.current.set(1, 0, 0).applyQuaternion(currentRotation);
    
    if (keys.current.lookUp) {
      // Rotate around the local right axis (pitch)
      const pitchQ = new THREE.Quaternion().setFromAxisAngle(xAxis.current, rotationSpeed);
      quaternion.current.premultiply(pitchQ);
      rotationChanged = true;
    }
    
    if (keys.current.lookDown) {
      // Rotate around the local right axis (pitch) - opposite direction
      const pitchQ = new THREE.Quaternion().setFromAxisAngle(xAxis.current, -rotationSpeed);
      quaternion.current.premultiply(pitchQ);
      rotationChanged = true;
    }
    
    // Get the local forward axis for proper roll rotation
    zAxis.current.set(0, 0, -1).applyQuaternion(currentRotation);
    
    if (keys.current.rollLeft) {
      // Rotate around the local forward axis (roll)
      const rollQ = new THREE.Quaternion().setFromAxisAngle(zAxis.current, rotationSpeed);
      quaternion.current.premultiply(rollQ);
      rotationChanged = true;
    }
    
    if (keys.current.rollRight) {
      // Rotate around the local forward axis (roll) - opposite direction
      const rollQ = new THREE.Quaternion().setFromAxisAngle(zAxis.current, -rotationSpeed);
      quaternion.current.premultiply(rollQ);
      rotationChanged = true;
    }
    
    // Normalize quaternion to prevent accumulated errors
    quaternion.current.normalize();
    
    // Create direction vectors based on current rotation
    forwardVector.current.set(0, 0, -1).applyQuaternion(quaternion.current);
    rightVector.current.set(1, 0, 0).applyQuaternion(quaternion.current);
    
    // Handle movement
    if (keys.current.forward) {
      position.x += forwardVector.current.x * speed;
      position.y += forwardVector.current.y * speed; 
      position.z += forwardVector.current.z * speed;
      positionChanged = true;
    }
    
    if (keys.current.backward) {
      position.x -= forwardVector.current.x * speed;
      position.y -= forwardVector.current.y * speed;
      position.z -= forwardVector.current.z * speed;
      positionChanged = true;
    }
    
    if (keys.current.strafeLeft) {
      position.x -= rightVector.current.x * speed;
      position.y -= rightVector.current.y * speed;
      position.z -= rightVector.current.z * speed;
      positionChanged = true;
    }
    
    if (keys.current.strafeRight) {
      position.x += rightVector.current.x * speed;
      position.y += rightVector.current.y * speed;
      position.z += rightVector.current.z * speed;
      positionChanged = true;
    }
    
    // Simple vertical movement (always along world Y)
    if (keys.current.up) {
      position.y += speed;
      positionChanged = true;
    }
    
    if (keys.current.down) {
      position.y -= speed;
      positionChanged = true;
    }
    
    // Update local state and send to server with throttling
    if (positionChanged) {
      localPosition.current = position;
      
      // Only send position updates at the specified interval
      if (now - lastPositionUpdate.current >= UPDATE_INTERVAL) {
        updatePosition(position);
        lastPositionUpdate.current = now;
      }
    }
    
    if (rotationChanged) {
      // Only send rotation updates at the specified interval
      if (now - lastRotationUpdate.current >= UPDATE_INTERVAL) {
        // Convert quaternion to Euler angles for the API
        const euler = new THREE.Euler().setFromQuaternion(quaternion.current, 'XYZ');
        
        const rotation = {
          x: euler.x,
          y: euler.y,
          z: euler.z
        };
        
        updateRotation(rotation);
        lastRotationUpdate.current = now;
      }
    }
  });
  
  // Add direct event listeners to document
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (ref.current) {
        ref.current.handleKeyDown(e);
      }
    };
    
    const handleKeyUp = (e) => {
      if (ref.current) {
        ref.current.handleKeyUp(e);
      }
    };
    
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);
    
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);
  
  return null;
});

export default Controls;