import React, { useRef, useMemo } from 'react';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';

// Simple spaceship component with LOD (Level of Detail)
function Spaceship({ position, rotation, color, isCurrentPlayer }) {
  const shipRef = useRef();
  const engineLightRef = useRef();
  
  // Calculate distance from camera - used for LOD
  const [distanceFromCamera, setDistanceFromCamera] = React.useState(0);
  
  // Create different detail levels based on geometry complexity
  const geometries = useMemo(() => {
    return {
      // High detail for close-up view
      high: {
        body: new THREE.ConeGeometry(1, 4, 8),
        wings: new THREE.BoxGeometry(4, 0.2, 1.5),
        cockpit: new THREE.SphereGeometry(0.7, 16, 16, 0, Math.PI * 2, 0, Math.PI / 2),
        engine: new THREE.CylinderGeometry(0.5, 0.8, 0.5, 16)
      },
      // Medium detail for mid-range view
      medium: {
        body: new THREE.ConeGeometry(1, 4, 6),
        wings: new THREE.BoxGeometry(4, 0.2, 1.5),
        cockpit: new THREE.SphereGeometry(0.7, 8, 8, 0, Math.PI * 2, 0, Math.PI / 2),
        engine: new THREE.CylinderGeometry(0.5, 0.8, 0.5, 8)
      },
      // Low detail for distant view
      low: {
        body: new THREE.ConeGeometry(1, 4, 4),
        wings: new THREE.BoxGeometry(4, 0.2, 1.5),
        cockpit: new THREE.SphereGeometry(0.7, 6, 6, 0, Math.PI * 2, 0, Math.PI / 2),
        engine: new THREE.CylinderGeometry(0.5, 0.8, 0.5, 6)
      }
    };
  }, []);
  
  // Animate engine light and update LOD based on distance
  useFrame((state) => {
    if (engineLightRef.current) {
      // Pulsating engine light
      engineLightRef.current.intensity = 1 + Math.sin(state.clock.elapsedTime * 10) * 0.3;
    }
    
    if (shipRef.current) {
      // Calculate distance from camera for LOD
      const distance = state.camera.position.distanceTo(
        new THREE.Vector3(position.x, position.y, position.z)
      );
      setDistanceFromCamera(distance);
    }
  });
  
  // Determine which LOD to use based on distance
  const getLODLevel = () => {
    if (distanceFromCamera < 20) return 'high';
    if (distanceFromCamera < 50) return 'medium';
    return 'low';
  };
  
  const lod = getLODLevel();
  const currentGeometries = geometries[lod];
  
  // Only render engine light and detail indicators for nearby ships
  const renderDetailedFeatures = lod === 'high';
  
  return (
    <group 
      ref={shipRef}
      position={[position.x, position.y, position.z]}
      rotation={[rotation.x, rotation.y, rotation.z]}
    >
      {/* Ship body */}
      <mesh castShadow receiveShadow>
        <primitive object={currentGeometries.body} />
        <meshStandardMaterial 
          color={color} 
          metalness={0.8}
          roughness={0.2}
          emissive={isCurrentPlayer ? "#ffffff" : "#000000"}
          emissiveIntensity={isCurrentPlayer ? 0.2 : 0}
        />
      </mesh>
      
      {/* Ship wings */}
      <mesh castShadow receiveShadow position={[0, -1, 0]}>
        <primitive object={currentGeometries.wings} />
        <meshStandardMaterial 
          color={color} 
          metalness={0.6}
          roughness={0.3}
        />
      </mesh>
      
      {/* Cockpit */}
      <mesh castShadow receiveShadow position={[0, 0.5, 0]}>
        <primitive object={currentGeometries.cockpit} />
        <meshStandardMaterial 
          color="#88ccff" 
          metalness={0.2}
          roughness={0.1}
          transparent={true}
          opacity={0.8}
        />
      </mesh>
      
      {/* Engine */}
      <mesh position={[0, -2.2, 0]}>
        <primitive object={currentGeometries.engine} />
        <meshStandardMaterial 
          color="#333333" 
          metalness={0.9}
          roughness={0.1}
        />
      </mesh>
      
      {/* Engine light - only for close ships */}
      {renderDetailedFeatures && (
        <pointLight 
          ref={engineLightRef}
          position={[0, -2.5, 0]} 
          color="#ff6600" 
          intensity={1.5} 
          distance={5}
        />
      )}
      
      {/* Orientation indicators - only for current player or close ships */}
      {(isCurrentPlayer || renderDetailedFeatures) && (
        <>
          {/* Forward direction (blue) */}
          <mesh position={[0, 0, -2.5]} scale={[0.2, 0.2, 0.2]}>
            <sphereGeometry args={[1, 8, 8]} />
            <meshBasicMaterial color="#0088ff" />
          </mesh>
          
          {/* Up direction (green) */}
          <mesh position={[0, 2.5, 0]} scale={[0.2, 0.2, 0.2]}>
            <sphereGeometry args={[1, 8, 8]} />
            <meshBasicMaterial color="#00ff88" />
          </mesh>
          
          {/* Right direction (red) */}
          <mesh position={[2.5, 0, 0]} scale={[0.2, 0.2, 0.2]}>
            <sphereGeometry args={[1, 8, 8]} />
            <meshBasicMaterial color="#ff0088" />
          </mesh>
        </>
      )}
      
      {/* Player indicator (only for current player) */}
      {isCurrentPlayer && (
        <pointLight 
          position={[0, 2, 0]} 
          color="#00ff00" 
          intensity={0.5} 
          distance={3}
        />
      )}
    </group>
  );
}

// Use React.memo to prevent unnecessary re-renders
export default React.memo(Spaceship, (prevProps, nextProps) => {
  // Only re-render if position or rotation changed significantly
  const positionThreshold = 0.01;
  const rotationThreshold = 0.01;
  
  const positionChanged = 
    Math.abs(prevProps.position.x - nextProps.position.x) > positionThreshold ||
    Math.abs(prevProps.position.y - nextProps.position.y) > positionThreshold ||
    Math.abs(prevProps.position.z - nextProps.position.z) > positionThreshold;
    
  const rotationChanged = 
    Math.abs(prevProps.rotation.x - nextProps.rotation.x) > rotationThreshold ||
    Math.abs(prevProps.rotation.y - nextProps.rotation.y) > rotationThreshold ||
    Math.abs(prevProps.rotation.z - nextProps.rotation.z) > rotationThreshold;
  
  // Return true if props are equal (don't re-render)
  return !(
    positionChanged || 
    rotationChanged || 
    prevProps.color !== nextProps.color ||
    prevProps.isCurrentPlayer !== nextProps.isCurrentPlayer
  );
}); 