import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import './App.css';
import GameScene from './components/GameScene';
import HUD from './components/HUD';
import StartScreen from './components/StartScreen';
import { v4 as uuidv4 } from 'uuid';

// Binary message constants - must match server
const BINARY_MESSAGE_TYPES = {
  POSITION_UPDATE: 1,
  ROTATION_UPDATE: 2,
  PLAYER_MOVED: 3,
  PLAYER_ROTATED: 4
};

// Memoize the binary message decoder
const createBinaryDecoder = () => {
  const decoder = new TextDecoder();
  return (buffer) => {
    const view = new DataView(buffer);
    const messageType = view.getUint8(0);
    
    // Extract player ID (16 bytes) and convert to string
    const idBytes = new Uint8Array(buffer.slice(1, 17));
    const id = [
      Array.from(idBytes.slice(0, 4)).map(b => b.toString(16).padStart(2, '0')).join(''),
      Array.from(idBytes.slice(4, 6)).map(b => b.toString(16).padStart(2, '0')).join(''),
      Array.from(idBytes.slice(6, 8)).map(b => b.toString(16).padStart(2, '0')).join(''),
      Array.from(idBytes.slice(8, 10)).map(b => b.toString(16).padStart(2, '0')).join(''),
      Array.from(idBytes.slice(10, 16)).map(b => b.toString(16).padStart(2, '0')).join('')
    ].join('-');
    
    if (messageType === BINARY_MESSAGE_TYPES.PLAYER_MOVED) {
      return {
        type: 'playerMoved',
        id,
        position: {
          x: view.getFloat32(17, true),
          y: view.getFloat32(21, true),
          z: view.getFloat32(25, true)
        }
      };
    }
    else if (messageType === BINARY_MESSAGE_TYPES.PLAYER_ROTATED) {
      return {
        type: 'playerRotated',
        id,
        rotation: {
          x: view.getFloat32(17, true),
          y: view.getFloat32(21, true),
          z: view.getFloat32(25, true)
        }
      };
    }
    return null;
  };
};

function App() {
  const [gameMode, setGameMode] = useState(null);
  const [connected, setConnected] = useState(false);
  const [playerId, setPlayerId] = useState(null);
  const [players, setPlayers] = useState({});
  const [socket, setSocket] = useState(null);
  const [supportsBinary, setSupportsBinary] = useState(false);
  const [gameStats, setGameStats] = useState({
    playerCount: 0,
    serverUptime: 0,
    binaryEnabled: false
  });
  const [retryCount, setRetryCount] = useState(0);
  const MAX_RETRIES = 5;
  const RETRY_INTERVAL = 3000;

  // Use refs for values that don't need to trigger re-renders
  const socketRef = useRef(null);
  const supportsBinaryRef = useRef(false);
  const playerIdRef = useRef(null);
  const gameModeRef = useRef(null);

  // Memoize the binary decoder
  const decodeBinaryMessage = useMemo(() => createBinaryDecoder(), []);

  // Batch player updates
  const updatePlayers = useCallback((updates) => {
    setPlayers(prevPlayers => {
      const newPlayers = { ...prevPlayers };
      Object.entries(updates).forEach(([id, update]) => {
        newPlayers[id] = {
          ...newPlayers[id],
          ...update
        };
      });
      return newPlayers;
    });
  }, []);

  // Optimized binary message creation
  const createBinaryMessage = useCallback((type, data) => {
    const buffer = new ArrayBuffer(29);
    const view = new DataView(buffer);
    view.setUint8(0, type);
    
    // Skip ID bytes as server will use connection ID
    for (let i = 1; i < 17; i++) {
      view.setUint8(i, 0);
    }
    
    if (type === BINARY_MESSAGE_TYPES.POSITION_UPDATE) {
      view.setFloat32(17, data.x, true);
      view.setFloat32(21, data.y, true);
      view.setFloat32(25, data.z, true);
    } else if (type === BINARY_MESSAGE_TYPES.ROTATION_UPDATE) {
      view.setFloat32(17, data.x, true);
      view.setFloat32(21, data.y, true);
      view.setFloat32(25, data.z, true);
    }
    
    return buffer;
  }, []);

  // Initialize offline mode
  const initOfflineMode = useCallback(() => {
    const offlineId = uuidv4();
    setPlayerId(offlineId);
    playerIdRef.current = offlineId;
    
    const offlinePlayers = {
      [offlineId]: {
        id: offlineId,
        position: { x: 0, y: 0, z: 0 },
        rotation: { x: 0, y: 0, z: 0 },
        color: getRandomColor(),
      }
    };
    
    setPlayers(offlinePlayers);
    setConnected(true);
    setGameStats({
      playerCount: 1,
      serverUptime: 0,
      binaryEnabled: false
    });
  }, []);

  // Start game in selected mode
  const handleStartGame = useCallback((mode) => {
    setGameMode(mode);
    gameModeRef.current = mode;
    
    if (mode === 'offline') {
      initOfflineMode();
    }
  }, [initOfflineMode]);

  // Initialize WebSocket connection for online mode
  useEffect(() => {
    if (gameMode !== 'online') return;

    const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const wsHost = window.location.hostname === 'localhost' ? 'localhost:3001' : window.location.host;
    const wsUrl = `wss://spaceone-new-start-game-server.onrender.com`;
    
    const ws = new WebSocket(wsUrl);
    ws.binaryType = 'arraybuffer';
    socketRef.current = ws;
    
    ws.onopen = () => {
      setConnected(true);
      setSocket(ws);
      setRetryCount(0);
    };
    
    ws.onmessage = (event) => {
      if (event.data instanceof ArrayBuffer) {
        const message = decodeBinaryMessage(event.data);
        if (!message) return;
        
        const updates = {};
        if (message.type === 'playerMoved') {
          updates[message.id] = { position: message.position };
        } else if (message.type === 'playerRotated') {
          updates[message.id] = { rotation: message.rotation };
        }
        updatePlayers(updates);
      } else {
        const message = JSON.parse(event.data);
        
        switch (message.type) {
          case 'init':
            setPlayerId(message.id);
            playerIdRef.current = message.id;
            
            const playersObj = {};
            message.players.forEach(player => {
              playersObj[player.id] = player;
            });
            setPlayers(playersObj);
            
            if (message.binarySupport) {
              setSupportsBinary(true);
              supportsBinaryRef.current = true;
              setGameStats(prev => ({ ...prev, binaryEnabled: true }));
              
              if (ws.readyState === WebSocket.OPEN) {
                ws.send(JSON.stringify({
                  type: 'binarySupport',
                  enabled: true
                }));
              }
            }
            break;
            
          case 'playerJoined':
            updatePlayers({ [message.player.id]: message.player });
            setGameStats(prev => ({
              ...prev,
              playerCount: Object.keys(players).length + 1
            }));
            break;
            
          case 'playerLeft':
            setPlayers(prevPlayers => {
              const newPlayers = { ...prevPlayers };
              delete newPlayers[message.id];
              return newPlayers;
            });
            setGameStats(prev => ({
              ...prev,
              playerCount: Object.keys(players).length - 1
            }));
            break;
            
          case 'playerMoved':
          case 'playerRotated':
            updatePlayers({
              [message.id]: {
                [message.type === 'playerMoved' ? 'position' : 'rotation']: 
                  message.type === 'playerMoved' ? message.position : message.rotation
              }
            });
            break;
        }
      }
    };
    
    ws.onclose = () => {
      setConnected(false);
      if (retryCount < MAX_RETRIES) {
        setTimeout(() => {
          setRetryCount(prev => prev + 1);
        }, RETRY_INTERVAL);
      } else {
        setGameMode('offline');
        initOfflineMode();
      }
    };
    
    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
      if (!connected) {
        setGameMode('offline');
        initOfflineMode();
      }
    };
    
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [gameMode, decodeBinaryMessage, initOfflineMode, retryCount, updatePlayers]);

  // Optimized position update function
  const updatePosition = useCallback((position) => {
    if (gameModeRef.current === 'offline') {
      updatePlayers({
        [playerIdRef.current]: { position }
      });
      return;
    }
    
    const ws = socketRef.current;
    if (ws?.readyState === WebSocket.OPEN) {
      if (supportsBinaryRef.current) {
        ws.send(createBinaryMessage(BINARY_MESSAGE_TYPES.POSITION_UPDATE, position));
      } else {
        ws.send(JSON.stringify({
          type: 'updatePosition',
          position
        }));
      }
    }
  }, [createBinaryMessage, updatePlayers]);

  // Optimized rotation update function
  const updateRotation = useCallback((rotation) => {
    if (gameModeRef.current === 'offline') {
      updatePlayers({
        [playerIdRef.current]: { rotation }
      });
      return;
    }
    
    const ws = socketRef.current;
    if (ws?.readyState === WebSocket.OPEN) {
      if (supportsBinaryRef.current) {
        ws.send(createBinaryMessage(BINARY_MESSAGE_TYPES.ROTATION_UPDATE, rotation));
      } else {
        ws.send(JSON.stringify({
          type: 'updateRotation',
          rotation
        }));
      }
    }
  }, [createBinaryMessage, updatePlayers]);

  // Memoize getRandomColor
  const getRandomColor = useMemo(() => () => {
    return '#' + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0');
  }, []);

  return (
    <div className="App">
      {gameMode === null ? (
        <StartScreen onStartGame={handleStartGame} />
      ) : (
        !connected && gameMode === 'online' ? (
          <div className="loading">
            <h1>Connecting to server...</h1>
          </div>
        ) : (
          <>
            <GameScene 
              playerId={playerId}
              players={players}
              updatePosition={updatePosition}
              updateRotation={updateRotation}
            />
            <HUD 
              playerId={playerId}
              playerCount={Object.keys(players).length}
              connected={connected}
              gameStats={{
                ...gameStats,
                binaryEnabled: supportsBinary,
                gameMode
              }}
            />
          </>
        )
      )}
    </div>
  );
}

export default App;
