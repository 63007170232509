import React from 'react';
import '../styles/HUD.css';

function HUD({ playerId, playerCount, connected, gameStats, onReturnToMenu }) {
  return (
    <div className="hud">
      <div className="hud-top">
        <div className="connection-status">
          <span className={`status-indicator ${connected ? (gameStats.gameMode === 'offline' ? 'offline' : 'connected') : 'disconnected'}`}></span>
          {connected ? (
            gameStats.gameMode === 'offline' ? 'Offline Mode' : 'Connected'
          ) : 'Disconnected'}
        </div>
        <div className="game-info">
          <div>Players: {playerCount}</div>
          {gameStats.gameMode !== 'offline' && (
            <div>Server Uptime: {formatTime(gameStats.serverUptime)}</div>
          )}
          {gameStats.gameMode === 'online' && gameStats.binaryEnabled && (
            <div className="binary-status">Binary Protocol: Active</div>
          )}
        </div>
        
        <button className="menu-btn" onClick={onReturnToMenu} title="Return to Main Menu">
          Menu
        </button>
      </div>
      
      <div className="hud-bottom">
        <div className="player-id">
          <div>Your ID: {playerId ? playerId.substring(0, 8) : 'Connecting...'}</div>
        </div>
        
        <div className="controls-help">
          <h3>Controls</h3>
          <div className="controls-grid">
            <div className="control-group">
              <h4>Movement</h4>
              <div className="control-item">
                <span className="key">W</span> Forward
              </div>
              <div className="control-item">
                <span className="key">S</span> Backward
              </div>
              <div className="control-item">
                <span className="key">A</span> Strafe Left
              </div>
              <div className="control-item">
                <span className="key">D</span> Strafe Right
              </div>
              <div className="control-item">
                <span className="key">Space</span> Up
              </div>
              <div className="control-item">
                <span className="key">Shift</span> Down
              </div>
            </div>
            
            <div className="control-group">
              <h4>Rotation</h4>
              <div className="control-item">
                <span className="key">←</span> Turn Left
              </div>
              <div className="control-item">
                <span className="key">→</span> Turn Right
              </div>
              <div className="control-item">
                <span className="key">Q</span> Roll Left
              </div>
              <div className="control-item">
                <span className="key">E</span> Roll Right
              </div>
              <div className="control-item">
                <span className="key">↑</span> Pitch Up
              </div>
              <div className="control-item">
                <span className="key">↓</span> Pitch Down
              </div>
            </div>
            
            <div className="control-group">
              <h4>Camera</h4>
              <div className="control-item">
                <span className="key">1</span> Follow View
              </div>
              <div className="control-item">
                <span className="key">2</span> Cockpit View
              </div>
              <div className="control-item">
                <span className="key">3</span> Orbit View
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// Helper function to format time in HH:MM:SS
function formatTime(seconds) {
  if (!seconds) return '00:00:00';
  
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;
  
  return [
    hours.toString().padStart(2, '0'),
    minutes.toString().padStart(2, '0'),
    secs.toString().padStart(2, '0')
  ].join(':');
}

export default HUD; 