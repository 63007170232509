import React, { useState } from 'react';
import '../styles/StartScreen.css';

function StartScreen({ onStartGame }) {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <div className="start-screen">
      <div className="start-content">
        <h1>Space<span className="highlight">One</span></h1>
        
        <div className="game-description">
          <p>Explore the vastness of space in your own spacecraft</p>
        </div>
        
        {!showInfo ? (
          <div className="menu-options">
            <button className="menu-button" onClick={() => onStartGame('online')}>
              Start Online Game
            </button>
            <button className="menu-button" onClick={() => onStartGame('offline')}>
              Play Offline
            </button>
            <button className="menu-button" onClick={() => setShowInfo(true)}>
              Game Information
            </button>
            <div className="server-status">
              <p>Server may be temporarily unavailable. If you have connection issues, use offline mode.</p>
            </div>
          </div>
        ) : (
          <div className="game-info">
            <h2>Game Information</h2>
            <div className="info-section">
              <h3>About the Game</h3>
              <p>SpaceOne is a multiplayer space simulation where you can navigate your spacecraft through the cosmos. 
              Connect with other players in real-time or practice your piloting skills offline.</p>
            </div>
            
            <div className="info-section">
              <h3>Controls</h3>
              <div className="controls-list">
                <div className="control-group">
                  <h4>Movement</h4>
                  <div><span className="key">W</span> Forward</div>
                  <div><span className="key">S</span> Backward</div>
                  <div><span className="key">A</span> Strafe Left</div>
                  <div><span className="key">D</span> Strafe Right</div>
                  <div><span className="key">Space</span> Up</div>
                  <div><span className="key">Shift</span> Down</div>
                </div>
                
                <div className="control-group">
                  <h4>Rotation</h4>
                  <div><span className="key">←</span> Turn Left</div>
                  <div><span className="key">→</span> Turn Right</div>
                  <div><span className="key">Q</span> Roll Left</div>
                  <div><span className="key">E</span> Roll Right</div>
                  <div><span className="key">↑</span> Pitch Up</div>
                  <div><span className="key">↓</span> Pitch Down</div>
                </div>
                
                <div className="control-group">
                  <h4>Camera</h4>
                  <div><span className="key">1</span> Follow View</div>
                  <div><span className="key">2</span> Cockpit View</div>
                  <div><span className="key">3</span> Orbit View</div>
                </div>
              </div>
            </div>
            
            <div className="info-section">
              <h3>Game Modes</h3>
              <p><strong>Online Mode:</strong> Connect to the game server and play with other pilots in real-time. Your position and movements are synchronized with all connected players.</p>
              <p><strong>Offline Mode:</strong> Practice your flight skills without connecting to the server. Perfect for when you want to play without an internet connection.</p>
            </div>
            
            <button className="back-button" onClick={() => setShowInfo(false)}>
              Back to Menu
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default StartScreen; 